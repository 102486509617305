import React from 'react'

const SearchStudentForReceiving = () => {
  return (
    <div>
    {/* =======Search Bar======= */}
        <div className='d-flex align-items-center'>
          <div className="well search-result flex-grow-1 mb-2">
            <div className="input-group">
              <input type="text" className="form-control rounded me-1" placeholder="Search Student Family ID" />
              <span className="input-group-btn">
                <button className="btn  btn-lg" style={{backgroundColor:"#102C57",color:"white"}} type="button">
                  <i className="glyphicon glyphicon-search" />
                  Search
                </button>
              </span>
            </div>
          </div>
        </div>

        {/* ========================== */}

        <div style={{  maxWidth: '1200px', margin: '0 auto' }}>
      <div
        style={{
          backgroundColor: '#CAF4FF',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          padding: '1rem',
          border: '2px solid #eaedf2'
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}>
          <div style={{ flex: '1 1 100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ margin: '0' }}><strong>Family Id:</strong> 375</p>
            <p style={{ margin: '0' }}><strong>Contact:</strong> 0300-0000000</p>
            
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}>
          <div style={{ flex: '1 1 100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ margin: '0' }}><strong>Father Name:</strong> Mahmood Tufail</p>
            <p style={{ margin: '0' }}><strong>WhatsApp:</strong> 0300-0000000</p>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}>
          <div style={{ flex: '1 1 100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ margin: '0' }}><strong>CNIC:</strong> 34135-8796744-8</p>
            <p style={{ margin: '0' }}><strong>Address:</strong> xyz</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default SearchStudentForReceiving