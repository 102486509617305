import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LoginComponent = () => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(loginId + " " + password);
    // Here you can perform your login logic
    // If login is successful, navigate to the desired page
    navigate('/panel');
  }

  return (
    <div className="relative z-10 bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
      <h1 className="text-center mb-4">
        <i className="fa-solid fa-house-user text-[#102C57] text-4xl"></i>
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="relative mb-4">
          <input
            type="text"
            id="floatingInput"
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
            className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
            placeholder=" "
          />
          <label
            htmlFor="floatingInput"
            className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
          >
            Login Id
          </label>
        </div>
        <div className="relative mb-4">
          <input
            type="password"
            id="floatingInput"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
            placeholder=" "
              
            />
          <label
            htmlFor="floatingInput"
            className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
          >
            Password
          </label>
        </div>
        <button className="bg-[#102C57] text-white w-full py-2 rounded mt-2" type="submit">Login</button>
      </form>
    </div>
  )
}

export default LoginComponent;
