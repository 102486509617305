import React, { useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { toast, ToastContainer } from 'react-toastify';

function StudentAttendancePage() {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [attendance, setAttendance] = useState({
    studentStatuses: [],
    isNewAttendance: false, // Added default state for new or existing attendance
  });
  useEffect(() => {
    fetch('https://dotnet.rapidmis.com/studentattendancelookup')
      .then((response) => response.json())
      .then((data) => {
        setClasses(data.classesWithSessionList);
      })
      .catch((error) => console.error('Error fetching lookup data:', error));
  }, []);

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    setMaxDate(`${yyyy}-${mm}-${dd}`);
  }, []);

  const handleClassChange = (e) => {
    const classId = e.target.value;
    setSelectedClass(classId);
    const selectedClassData = classes.find((cls) => cls.classId === parseInt(classId));
    if (selectedClassData) {
      setSections(selectedClassData.sectionList || []);
    } else {
      setSections([]);
    }
    setSelectedSection('');
  };



  const handleStudentChange = (index, type) => {
    setAttendance((prevState) => {
      const updatedStatuses = [...prevState.studentStatuses];
      updatedStatuses[index] = {
        present: type === 'present',
        absent: type === 'absent',
        late: type === 'late',
        leave: type === 'leave',
      };
      return {
        ...prevState,
        studentStatuses: updatedStatuses,
      };
    });
  };

  const handleHeaderChange = (type) => {
    setAttendance((prevState) => ({
      ...prevState,
      studentStatuses: prevState.studentStatuses.map(() => ({
        present: type === 'present',
        absent: type === 'absent',
        late: type === 'late',
        leave: type === 'leave',
      })),
    }));
  };

  const handleSearch = () => {
    if (!selectedClass || !selectedSection || !selectedDate) {
      toast.error('Please select class, section, and date.');
      return;
    }
  
    const apiUrl = `https://dotnet.rapidmis.com/studentattendancebyfilter?ClassId=${selectedClass}&SectionId=${selectedSection}&AttendanceDate=${selectedDate}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setStudents(data);
  
          // Check if any student's status is null
          const isNewAttendance = data.some((student) => student.status === null);
  
          // Update attendance state
          setAttendance((prevState) => ({
            ...prevState,
            studentStatuses: data.map((student) => ({
              present: student.status === 'Present',
              absent: student.status === 'Absent',
              late: student.status === 'Late',
              leave: student.status === 'Leave',
            })),
            isNewAttendance,  // Store if this is a new attendance
          }));
        } else {
          console.warn('Unexpected data format:', data);
          setStudents([]);
          setAttendance({ studentStatuses: [], isNewAttendance: true }); // Assume it's new if data is invalid
        }
      })
      .catch((error) => {
        console.error('Error fetching student attendance:', error);
        alert('An error occurred while fetching student attendance.');
      });
  };
  
  const handleSave = () => {
    const attendanceDetailList = students.map((student, index) => {
      const studentStatus = attendance.studentStatuses[index];
      let status = '';
      if (studentStatus.present) status = 'Present';
      if (studentStatus.absent) status = 'Absent';
      if (studentStatus.late) status = 'Late';
      if (studentStatus.leave) status = 'Leave';
  
      return {
        instanceId: student.studentId,
        status: status,
        remarks: '',
      };
    });
  
    const attendanceData = {
      attendanceTypeId: 1,
      attendanceDate: selectedDate,
      attendanceDetailList: attendanceDetailList,
    };
  
    const apiUrl = attendance.isNewAttendance
      ? 'https://dotnet.rapidmis.com/attendancecreate'
      : 'https://dotnet.rapidmis.com/attendanceupdate';
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(attendanceData),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Attendance saved successfully');
        } else {
          toast.error('Failed to save attendance');
        }
      })
      .catch((error) => {
        console.error('Error saving attendance:', error);
        toast.error('An error occurred while saving attendance');
      });
  };
  
  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const day = selectedDate.getDay();
    if (day === 0) {
      toast.error("Sunday is not allowed.");
      e.target.value = '';
    } else {
      setSelectedDate(e.target.value);
    }
  };

  return (
    <div className='space-y-4'>
      <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">Filters</h4>
        <div className='flex flex-col md:flex-row md:space-x-2 my-2'>
          <div className='flex-grow md:w-1/4'>
            <div className='relative'>
              <MenuOutlined className='absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none' />
              <select
                className='block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50'
                value={selectedClass}
                onChange={handleClassChange}
              >
                <option value=''>Select Class</option>
                {classes.map((cls) => (
                  <option key={cls.classId} value={cls.classId}>
                    {cls.classDisplayName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex-grow md:w-1/4'>
            <div className='relative'>
              <MenuOutlined className='absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none' />
              <select
                className='block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50'
                value={selectedSection}
                onChange={(e) => setSelectedSection(e.target.value)}
                disabled={sections.length === 0}
              >
                <option value=''>Select Section</option>
                {sections.map((section) => (
                  <option key={section.sectionId} value={section.sectionId}>
                    {section.sectionDisplayName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex-grow md:w-1/4'>
            <div className="relative">
              <input
                type="date"
                className="block w-full pr-3 pl-3 py-2 text-sm text-gray-900 bg-gray-50 border border-gray-300"
                value={selectedDate}
                onChange={handleDateChange}
                min={minDate}
                max={maxDate}
              />
            </div>
          </div>
          <div className='flex-grow md:w-1/4'>
            <button className="bg-blue-500 w-full text-white py-2 hover:bg-blue-600" onClick={handleSearch}>
              <i className="fa-solid fa-filter"></i> Filter
            </button>
          </div>
        </div>
      </div>

      <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Student Attendance</h4>
        <div className="overflow-x-auto shadow-md">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-themeColor1">
                <th className="px-4 py-2 text-left text-white">Name</th>
                <th className="px-4 py-2 text-white">Reg No</th>
                <th className="px-4 py-2 text-white">Family Id</th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('present')}
                  /> Present
                </th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('absent')}
                  /> Absent
                </th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('late')}
                  /> Late
                </th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('leave')}
                  /> Leave
                </th>
              </tr>
            </thead>
            <tbody>
              {students.length === 0 ? (
                <tr>
                <td colSpan="7" className="border-b border-gray-300 px-2 py-3 text-center">
                  <Empty />
                </td>
              </tr>
              ) : (
                students.map((student, index) => (
                  <tr key={student.studentId}>
                    <td className="px-4 py-2">{student.studentName}</td>
                    <td className="px-4 py-2">{student.regNo}</td>
                    <td className="px-4 py-2">{student.familyId}</td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`present${index}`}
                        value="present"
                        checked={attendance.studentStatuses[index]?.present || false}
                        onChange={() => handleStudentChange(index, 'present')}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`absent${index}`}
                        value="absent"
                        checked={attendance.studentStatuses[index]?.absent || false}
                        onChange={() => handleStudentChange(index, 'absent')}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`late${index}`}
                        value="late"
                        checked={attendance.studentStatuses[index]?.late || false}
                        onChange={() => handleStudentChange(index, 'late')}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`leave${index}`}
                        value="leave"
                        checked={attendance.studentStatuses[index]?.leave || false}
                        onChange={() => handleStudentChange(index, 'leave')}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/* Save Button */}
        <div className='flex justify-end mb-2 mt-2 text-center'>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600" onClick={handleSave}>
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
      </div>  
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
}

export default StudentAttendancePage;
