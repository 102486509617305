import React from 'react';
import FeeDetailForReceiving from './FeeDetailForReceiving';
import FeeHistoryForReceiving from './FeeHistoryForReceiving';
import SearchStudentForReceiving from './SearchStudentForReceiving';

const FeeDetailAndHistory = () => {
  return (
    <div className="" >
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-12">
              <div className="">
              <SearchStudentForReceiving/>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="">
                <FeeDetailForReceiving />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="">
            <FeeHistoryForReceiving />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeDetailAndHistory;
