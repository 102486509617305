import { Empty } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    axios
      .get('https://dotnet.rapidmis.com/rolepermissiongetall', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => console.error('Error fetching role data:', error));
  }, []);

  // Handle add new button click
  const handleAddNew = () => {
    navigate('/panel/add-edit-role-permission'); // Navigate to the add-edit-role-permission page
  };

  // Handle edit role click
  const handleEdit = (roleId) => {
    navigate(`/panel/add-edit-role-permission/${roleId}`); // Navigate with roleId for editing
  };

  return (
    <div className='bg-white p-2'>
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">ROLE PERMISSION LIST</h4>
      {/* Add New Button */}
      <div className="flex justify-end mt-2">
        <button
          className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
          onClick={handleAddNew} // Add New role navigation
        >
          <i className="fa-solid fa-plus"></i> Add New
        </button>
      </div>
      <div className="mt-2 overflow-y-auto shadow-md" style={{ maxHeight: '500px' }}>
        <table className="min-w-full bg-white">
          <thead className="bg-themeColor1 sticky top-0 z-10">
            <tr>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Sr</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Role Name</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            {roles.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <div className="flex flex-col items-center justify-center my-10">
                    <Empty />
                  </div>
                </td>
              </tr>
            ) : (
              roles.map((role, index) => (
                <tr key={role.rolePermissionId} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b border-gray-300">{index + 1}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{role.rolePermissionName}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <i
                      className="fa-regular text-lg fa-pen-to-square cursor-pointer"
                      onClick={() => handleEdit(role.rolePermissionId)} // Edit role navigation
                    ></i>
                    <i className="fa-regular fa-trash-can ml-4 cursor-pointer"></i>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RolePermissions;
