import React, { useState } from 'react';

const FeeConcessionsPage = () => {
  const [resultData, setResultData] = useState([
    { rn: '1', name: 'Feroz', fatherName: 'Mahmood Tufail', class: 'One', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { rn: '2', name: 'Feroz', fatherName: 'Mahmood Tufail', class: 'Two', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { rn: '3', name: 'Feroz', fatherName: 'Mahmood Tufail', class: 'Three', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { rn: '4', name: 'Feroz', fatherName: 'Mahmood Tufail', class: 'Four', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
  ]);

  const feeHead = [
    { addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
  ];

  const handleInputChange = (index, field, value) => {
    const updatedData = [...resultData];
    updatedData[index][field] = value;
    setResultData(updatedData);
  };

  return (
    <div className='space-y-4'>
      <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Filters</h4>
        <div className="flex flex-col md:flex-row md:justify-between">
          {/* Dropdown 1 */}
          <div className="mb-4 md:mb-0 md:w-1/3">
            <select className="block w-full p-2 border border-gray-300 rounded">
              <option value=''><i className="fa-solid fa-sliders"></i> Select Class</option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>

          {/* Dropdown 2 */}
          <div className="mb-4 md:mb-0 md:w-1/3">
            <select className="block w-full p-2 border border-gray-300 rounded">
              <option value=''>Select Section</option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>

          <div className="md:w-1/3 flex justify-end">
            <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200">
              <i className="fa-solid fa-magnifying-glass"></i> Search
            </button>
          </div>
        </div>
      </div>

      <div className="">
        <table className="min-w-full border-b border-gray-300 bg-white">
          <thead className="bg-themeColor1 text-white">
            <tr>
              <th className="px-4 py-2">ADMISSION FEE</th>
              <th className="px-4 py-2">ANNUAL FUND</th>
              <th className="px-4 py-2">EXAMINATION FEE</th>
              <th className="px-4 py-2">TUITION FEE</th>
              <th className="px-4 py-2">GENERATOR FEE</th>
              <th className="px-4 py-2">AC FEE</th>
              <th className="px-4 py-2">PRACTICALS FEE</th>
            </tr>
          </thead>
          <tbody>
            {feeHead.map((data, index) => (
              <tr key={index} className="bg-gray-50 hover:bg-gray-100">
                <td className="px-4 py-2">{data.addFee}</td>
                <td className="px-4 py-2">{data.annFund}</td>
                <td className="px-4 py-2">{data.examFee}</td>
                <td className="px-4 py-2">{data.tutionFee}</td>
                <td className="px-4 py-2">{data.gFee}</td>
                <td className="px-4 py-2">{data.acFee}</td>
                <td className="px-4 py-2">{data.pFee}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-white p-2">
        <table className="border-b border-gray-300 shadow">
          <thead className="bg-themeColor1 text-white">
            <tr>
              <th className="px-4 py-2">Roll No</th>
              <th className="px-4 py-2">Student Name</th>
              <th className="px-4 py-2">Father Name</th>
              <th className="px-4 py-2">Class</th>
              <th className="px-4 py-2">ADMISSION FEE</th>
              <th className="px-4 py-2">ANNUAL FUND</th>
              <th className="px-4 py-2">EXAMINATION FEE</th>
              <th className="px-4 py-2">TUITION FEE</th>
              <th className="px-4 py-2">GENERATOR FEE</th>
              <th className="px-4 py-2">AC FEE</th>
              <th className="px-4 py-2">PRACTICALS FEE</th>
            </tr>
          </thead>
          <tbody>
            {resultData.map((data, index) => (
              <tr key={index} className="bg-gray-50 hover:bg-gray-100">
                <td className=" border-b px-4 py-2">{data.rn}</td>
                <td className=" border-b px-4 py-2">{data.name}</td>
                <td className=" border-b px-4 py-2">{data.fatherName}</td>
                <td className=" border-b px-4 py-2">{data.class}</td>
                <td className=" border-b px-4 py-2">
                  <input
                    className="w-full border-b text-center border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.addFee}
                    onChange={(e) => handleInputChange(index, 'addFee', e.target.value)}
                  />
                </td>
                <td className="border px-4 py-2 text-center">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.annFund}
                    onChange={(e) => handleInputChange(index, 'annFund', e.target.value)}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.examFee}
                    onChange={(e) => handleInputChange(index, 'examFee', e.target.value)}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.tutionFee}
                    onChange={(e) => handleInputChange(index, 'tutionFee', e.target.value)}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.gFee}
                    onChange={(e) => handleInputChange(index, 'gFee', e.target.value)}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.acFee}
                    onChange={(e) => handleInputChange(index, 'acFee', e.target.value)}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-right"
                    type="number"
                    value={data.pFee}
                    onChange={(e) => handleInputChange(index, 'pFee', e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex justify-end mt-2 text-center'>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600">
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
      </div>

     
    </div>
  );
};

export default FeeConcessionsPage;
