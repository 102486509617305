import React, { useState, useEffect } from 'react';

function FinancePage() {
  const department = [
    "Building Maintenance", "Furniture", "Freshments", "Bike", "Salary"
  ];

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const date = today.toISOString().split('T')[0];
    setCurrentDate(date);
  }, []);

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        
        <div className="space-y-2">
        {/* Expenditure Section */}
          <div className='bg-white'>
          <h3 className="py-2 bg-themeColor1 text-white"><strong>EXPENDITURE</strong></h3>
          <form className=" mt-2 p-2">
            <fieldset className="">
              <div className="mb-4">
                <label className="block text-gray-700">Ledger</label>
                <select className="form-select mt-1 block w-full p-2 border border-gray-300 rounded text-black">
                  <option value="">Select Ledger</option>
                  {department.map((dept, index) => (
                    <option key={index} value={dept}>{dept}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Date Received</label>
                <input type="date" className="mt-1 block w-full p-2 border border-gray-300 rounded" style={{ color: "#C7B7A3" }} value={currentDate} readOnly />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Detail</label>
                <input type="text" className="mt-1 block w-full p-2 border border-gray-300 rounded text-black" placeholder="" />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Total Expenses</label>
                <input type="text" className="mt-1 block w-full p-2 border border-gray-300 rounded text-black" placeholder="" />
              </div>
            </fieldset>
            <div className='flex justify-end mb-2 mt-2 text-center'>
              <button className="bg-blue-500 text-white px-9 py-2 hover:bg-blue-600">
                Add
              </button>
            </div>
          </form>
          </div>

          {/* Balance Sheet Section */}
          <div className="bg-white">
            <h3 className="bg-themeColor1 py-2 text-white"><strong>BALANCE SHEET</strong></h3>
            <table className="w-full">
              <tbody>
                <tr className="border-b">
                  <td className="py-2 font-bold">TOTAL EXPENDITURE</td>
                  <td className="py-2">35000</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-bold">TOTAL RECEIVINGS</td>
                  <td className="py-2">5000</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-bold">BALANCE</td>
                  <td className="py-2">30000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* History Section */}
        <div className="bg-white">
        <h3 className="py-2 bg-themeColor1 text-white"><strong>RECEIVINGS</strong></h3>
            <table className="table-auto w-full border-collapse border-b border-gray-300 mb-0">
              <thead>
                <tr className='bg-gray-200'>
                  <th className="text-black border-b border-gray-300 p-2">FID/REG</th>
                  <th className="text-black border-b border-gray-300 p-2">STUDENT NAME</th>
                  <th className="text-black border-b border-gray-300 p-2">CLASS</th>
                  <th className="text-black border-b border-gray-300 p-2">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-gray-300 p-2"><strong>375</strong></td>
                  <td className="border-b border-gray-300 p-2">Muhammad Feroz</td>
                  <td className="border-b border-gray-300 p-2">Eighth</td>
                  <td className="border-b border-gray-300 p-2">0</td>
                </tr>
                {/* Repeat above tr for more entries */}
              </tbody>
              <tfoot>
                <tr className='bg-[#006769]'>
                  <td className="text-white p-2"><span>Total Receivings</span></td>
                  <td className="text-white p-2"></td>
                  <td className="text-white p-2"></td>
                  <td className="text-white p-2"><span>35000</span></td>
                </tr>
              </tfoot>
            </table>
        </div>
      </div>
    </div>
  );
}

export default FinancePage;
