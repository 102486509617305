import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { 
  TeamOutlined, 
  SettingOutlined, 
  DashboardOutlined,
  BarChartOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom'; // Import useLocation to track current route

const { SubMenu } = Menu;

const Sidebar = ({ onNavigation }) => {
  const location = useLocation(); // Get the current route
  const [selectedKeys, setSelectedKeys] = useState([]); // Track selected keys
  const [openKeys, setOpenKeys] = useState([]); // Track open submenu keys

  useEffect(() => {
    // Set the selected key based on the current path
    setSelectedKeys([location.pathname]);
  }, [location]);

  const handleNavigation = (page) => {
    onNavigation(page);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={selectedKeys} // Set the selected keys dynamically
      onClick={({ key }) => handleNavigation(key)} // Handle navigation
      openKeys={openKeys} // Track open submenus
      onOpenChange={onOpenChange} // Handle submenu open changes
      style={{ paddingTop: 0 }}
      // className='bg-themeColor'
    >
      <h1 className='text-lg text-white mt-3'>
        <strong>CAMPUS PANEL</strong>
      </h1>
      <Menu.Item key="/panel/dashboardCampus" icon={<DashboardOutlined />} >
        <div>Dashboard</div>
      </Menu.Item>

      <SubMenu key="student" icon={<TeamOutlined />} title={<span style={{ textAlign: 'left', display: 'block' }}>Student Management</span>}>
        <Menu.Item key="/panel/viewStudentList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div>Students List</div>
        </Menu.Item>
        <Menu.Item key="/panel/familySummary" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div>Family Summary</div>
        </Menu.Item>
        {/* <Menu.Item key="/panel/addStudent" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Add Student</div>
        </Menu.Item> */}
        <Menu.Item key="/panel/attendence" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Attendance</div>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="staff" icon={<FontAwesomeIcon icon={faGraduationCap} />} title={<span style={{ textAlign: 'left', display: 'block' }}>Staff Management</span>}>
        <Menu.Item key="/panel/viewStaff" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Staff List</div>
        </Menu.Item>
        {/* <Menu.Item key="/panel/addStaff" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Add Staff</div>
        </Menu.Item> */}
        <Menu.Item key="/panel/staffAttendence" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Staff Attendance</div>
        </Menu.Item>
        <Menu.Item key="/panel/staffResult" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Staff Result</div>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="finance" icon={<FontAwesomeIcon icon={faBuildingColumns} />} title={<span style={{ textAlign: 'left', display: 'block' }}>Finance Management</span>}>
        <Menu.Item key="/panel/feeReceiving" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Fee Receiving</div>
        </Menu.Item>
        <Menu.Item key="/panel/financePage" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Finance Page</div>
        </Menu.Item>
        <Menu.Item key="/panel/feeBill" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Fee Bill</div>
        </Menu.Item>
        <Menu.Item key="/panel/assignFee" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Assign Fee</div>
        </Menu.Item>
        <Menu.Item key="/panel/feeConcessions" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Fee Concessions</div>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="results" icon={<BarChartOutlined />} title={<span style={{ textAlign: 'left', display: 'block' }}>Exam Management</span>}>
        <Menu.Item key="/panel/datesheet" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Date Sheet</div>
        </Menu.Item>
        <Menu.Item key="/panel/addresults" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Results</div>
        </Menu.Item>
        <Menu.Item key="/panel/viewResult" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>View Results</div>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="settings" icon={<SettingOutlined />} title={<span style={{ textAlign: 'left', display: 'block' }}>Settings</span>}>
        <Menu.Item key="/panel/classList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Classes</div>
        </Menu.Item>
        <Menu.Item key="/panel/sectionList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Sections</div>
        </Menu.Item>
        <Menu.Item key="/panel/MediumList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Mediums</div>
        </Menu.Item>
        <Menu.Item key="/panel/rolePermission" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Role Permissions</div>
        </Menu.Item>
        <Menu.Item key="/panel/departmentList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Departments</div>
        </Menu.Item>
        <Menu.Item key="/panel/designationList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Designations</div>
        </Menu.Item>
        <Menu.Item key="/panel/campusList" style={{ paddingLeft: '30px' }} icon={<ArrowRightOutlined />}>
          <div className='pull-left'>Campuses</div>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default Sidebar;
