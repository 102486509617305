import React, { useState } from 'react';

const AssignFeePage = () => {
  const [resultData, setResultData] = useState([
    { class: 'One', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { class: 'Two', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { class: 'Three', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { class: 'Four', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
    { class: 'Five', addFee: 0, annFund: 0, examFee: 0, tutionFee: 0, gFee: 0, acFee: 0, pFee: 0 },
  ]);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...resultData];
    updatedData[index][field] = value;
    setResultData(updatedData);
  };

  return (
    <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Fee Assignment</h4>
      <table className="shadow">
        <thead className="bg-themeColor1 text-white">
          <tr>
            <th className="px-4 py-2">Class</th>
            <th className="px-4 py-2">ADMISSION FEE</th>
            <th className="px-4 py-2">ANNUAL FUND</th>
            <th className="px-4 py-2">EXAMINATION FEE</th>
            <th className="px-4 py-2">TUITION FEE</th>
            <th className="px-4 py-2">GENERATOR FEE</th>
            <th className="px-4 py-2">AC FEE</th>
            <th className="px-4 py-2">PRACTICALS FEE</th>
          </tr>
        </thead>
        <tbody>
          {resultData.map((data, index) => (
            <tr key={index} className="bg-gray-50 hover:bg-gray-100">
              <td className="px-4 py-2">{data.class}</td>
              {Object.keys(data).slice(1).map((field) => (
                <td key={field} className="px-4 py-2">
                  <input
                    className="w-full border-b border-gray-400 focus:outline-none focus:border-blue-500 text-center"
                    type="number"
                    value={data[field]}
                    onChange={(e) => handleInputChange(index, field, e.target.value)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex justify-end mb-2 mt-2 text-center'>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600">
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
    </div>
  );
};

export default AssignFeePage;
