import React, { useState } from 'react';

const CreateFamilyChallan = () => {
  const [month, setMonth] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleIssueDateChange = (e) => {
    setIssueDate(e.target.value);
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({
      month,
      issueDate,
      dueDate,
    });
    // You can add further logic for form submission
  };

  return (
    <div className='p-6 min-h-screen'>
      <h4 className='text-left font-bold mb-4'>Create Family Challan</h4>
      <div className="my-4 flex flex-wrap">
        <div className="w-full lg:w-2/3 mb-4 p-3">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="reg" className="block text-left font-semibold mb-2">
                <strong>Enter FId *</strong>
              </label>
              <input type="text" className="form-input block w-full text-black p-2 border border-gray-300 rounded" placeholder="Enter Family Id" required />
            </div>
            <div className="mb-4">
              <label htmlFor="month" className="block text-left font-semibold mb-2">
                <strong>Select Month *</strong>
              </label>
              <select
                className="form-select block w-full p-2 border border-gray-300 rounded"
                id="month"
                value={month}
                onChange={handleMonthChange}
                required
              >
                <option value="">Select Month</option>
                <option value="JANUARY">JANUARY</option>
                <option value="FEBRUARY">FEBRUARY</option>
                <option value="MARCH">MARCH</option>
                <option value="APRIL">APRIL</option>
                <option value="MAY">MAY</option>
                <option value="JUNE">JUNE</option>
                {/* Add more months as needed */}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="issueDate" className="block text-left font-semibold mb-2">
                <strong>Issue Date *</strong>
              </label>
              <input
                type="date"
                className="form-input block w-full p-2 border border-gray-300 rounded"
                id="issueDate"
                value={issueDate}
                onChange={handleIssueDateChange}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="dueDate" className="block text-left font-semibold mb-2">
                <strong>Due Date *</strong>
              </label>
              <input
                type="date"
                className="form-input block w-full p-2 border border-gray-300 rounded"
                id="dueDate"
                value={dueDate}
                onChange={handleDueDateChange}
                required
              />
            </div>
          </form>
        </div>

        <div className="w-full lg:w-1/3 mb-4 p-3">
          <form onSubmit={handleSubmit}>
            <h4 className="text-left font-bold mb-4"><strong>FEE TYPE</strong></h4>
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="tuitionFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="tuitionFee"
                >
                  TUITION FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="transportFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="transportFee"
                >
                  TRANSPORT FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="admissionFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="admissionFee"
                >
                  ADMISSION FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="annualFund"
                />
                <label
                  className="form-check-label"
                  htmlFor="annualFund"
                >
                  ANNUAL FUND
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="registrationFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="registrationFee"
                >
                  REGISTRATION FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="practicalsFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="practicalsFee"
                >
                  PRACTICALS FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="boardFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="boardFee"
                >
                  BOARD FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="securityFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="securityFee"
                >
                  SECURITY FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="examinationFee"
                />
                <label
                  className="form-check-label"
                  htmlFor="examinationFee"
                >
                  EXAMINATION FEE
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="acCharges"
                />
                <label
                  className="form-check-label"
                  htmlFor="acCharges"
                >
                  A/C CHARGES
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input mr-2"
                  type="checkbox"
                  id="stationary"
                />
                <label
                  className="form-check-label"
                  htmlFor="stationary"
                >
                  STATIONARY
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600">
        <i className="fa-solid fa-plus"></i> GENERATE CHALLANS
      </button>
    </div>
  );
};

export default CreateFamilyChallan;
