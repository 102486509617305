import React from 'react';

const FeeReceivingForm = () => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return (
    <div>
      <div className="content-panel">
        <form className="form-horizontal">
          <fieldset className="fieldset">
            <div className="form-group row">
              <label className="col-md-2 col-sm-3 col-12 control-label">Month From</label>
              <div className="col-md-10 col-sm-9 col-12">
                <select className="form-control text-black">
                  <option value="">Select Month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 col-sm-3 col-12 control-label">Month To</label>
              <div className="col-md-10 col-sm-9 col-12">
                <select className="form-control text-black">
                  <option value="">Select Month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 col-sm-3 col-12 control-label">Date Received</label>
              <div className="col-md-10 col-sm-9 col-12">
                <input type="date" className="form-control text-black" />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 col-sm-3 col-12 control-label">Adjustment</label>
              <div className="col-md-10 col-sm-9 col-12">
                <input type="text" className="form-control text-black" placeholder="" />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 col-sm-3 col-12 control-label">Total Payment</label>
              <div className="col-md-10 col-sm-9 col-12">
                <input type="text" className="form-control text-black" placeholder="" />
              </div>
            </div>
          </fieldset>
          <hr />
          <button class="usbutton" >RECEIVE</button>
        </form>
      </div>
    </div>
  );
};

export default FeeReceivingForm;
