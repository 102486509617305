import React, { useState } from 'react';

const CreateStudentChallan = () => {
  const [month, setMonth] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleIssueDateChange = (e) => {
    setIssueDate(e.target.value);
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      month,
      issueDate,
      dueDate,
    });
  };

  return (
    <div className='p-6 min-h-screen relative'>
      <h4 className='text-left font-bold text-lg'>Create Student Challan</h4>
      <div className="my-8">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-2/3 mb-6">
            <div className="p-6 bg-white rounded shadow">
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label htmlFor="reg" className="block text-left font-semibold mb-2">
                    Enter Reg No *
                  </label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded"
                    placeholder="Enter Reg"
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="month" className="block text-left font-semibold mb-2">
                    Select Month *
                  </label>
                  <select
                    className="w-full px-4 py-2 border border-gray-300 rounded"
                    id="month"
                    value={month}
                    onChange={handleMonthChange}
                    required
                  >
                    <option value="">Select Month</option>
                    <option value="JANUARY">JANUARY</option>
                    <option value="FEBRUARY">FEBRUARY</option>
                    <option value="MARCH">MARCH</option>
                    <option value="APRIL">APRIL</option>
                    <option value="MAY">MAY</option>
                    <option value="JUNE">JUNE</option>
                  </select>
                </div>
                <div className="mb-6">
                  <label htmlFor="issueDate" className="block text-left font-semibold mb-2">
                    Issue Date *
                  </label>
                  <input
                    type="date"
                    className="w-full px-4 py-2 border border-gray-300 rounded"
                    id="issueDate"
                    value={issueDate}
                    onChange={handleIssueDateChange}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="dueDate" className="block text-left font-semibold mb-2">
                    Due Date *
                  </label>
                  <input
                    type="date"
                    className="w-full px-4 py-2 border border-gray-300 rounded"
                    id="dueDate"
                    value={dueDate}
                    onChange={handleDueDateChange}
                    required
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="w-full lg:w-1/3 mb-6">
            <div className="p-6 bg-white rounded shadow">
              <form onSubmit={handleSubmit}>
                <h4 className="text-left font-bold text-lg mb-4">FEE TYPE</h4>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="tuitionFee"
                    />
                    <label htmlFor="tuitionFee" className="text-left">
                      TUITION FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="transportFee"
                    />
                    <label htmlFor="transportFee" className="text-left">
                      TRANSPORT FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="admissionFee"
                    />
                    <label htmlFor="admissionFee" className="text-left">
                      ADMISSION FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="annualFund"
                    />
                    <label htmlFor="annualFund" className="text-left">
                      ANNUAL FUND
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="registrationFee"
                    />
                    <label htmlFor="registrationFee" className="text-left">
                      REGISTRATION FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="practicalsFee"
                    />
                    <label htmlFor="practicalsFee" className="text-left">
                      PRACTICALS FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="boardFee"
                    />
                    <label htmlFor="boardFee" className="text-left">
                      BOARD FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="securityFee"
                    />
                    <label htmlFor="securityFee" className="text-left">
                      SECURITY FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="examFee"
                    />
                    <label htmlFor="examFee" className="text-left">
                      EXAMINATION FEE
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="acCharges"
                    />
                    <label htmlFor="acCharges" className="text-left">
                      A/C CHARGES
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="stationaryFee"
                    />
                    <label htmlFor="stationaryFee" className="text-left">
                      STATIONARY
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <button className="px-6 py-3 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 focus:outline-none">
        <i className="fa-solid fa-plus"></i> GENERATE CHALLANS
      </button>
    </div>
  );
};

export default CreateStudentChallan;
