import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Empty } from 'antd';

function AddDesignation() {
  const [designationData, setDesignationData] = useState([]);
  const [newDesignation, setNewDesignation] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    fetchDesignations();
  }, []);

  const fetchDesignations = () => {
    axios.get('https://dotnet.rapidmis.com/designationgetall', {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      setDesignationData(response.data);
    })
    .catch(error => console.error('Error fetching data:', error));
  };

  const handleAddNewRow = () => {
    const newRow = { designationId: null, designationName: '', isActive: true };
    setNewDesignation(newRow);
  };

  const handleSaveNewDesignation = () => {
    if (!newDesignation || !newDesignation.designationName?.trim()) {
      alert('Please enter Designation Name');
      return;
    }

    axios.post('https://dotnet.rapidmis.com/designationcreate', newDesignation, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      setNewDesignation(null);
      toast.success('Designation added successfully!');
      fetchDesignations();
    })
    .catch(error => console.error('Error adding new designation:', error));
  };

  const handleNewDesignationInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewDesignation({
      ...newDesignation,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleInputChange = (e, id) => {
    const { name, value, type, checked } = e.target;
    setUpdatedData(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };

  const handleEdit = (designationItem) => {
    setEditingId(designationItem.designationId);
    setUpdatedData({
      [designationItem.designationId]: { 
        designationName: designationItem.designationName,
        isActive: designationItem.isActive 
      }
    });
  };

  const handleSave = (id) => {
    const updatedDesignation = updatedData[id];
    axios.post('https://dotnet.rapidmis.com/designationupdate', {
        designationId: id,
        designationName: updatedDesignation.designationName || '',
        isActive: updatedDesignation.isActive
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        setEditingId(null);
        toast.success('Designation updated successfully!');
        fetchDesignations();
    })
    .catch(error => console.error('Error updating data:', error));
  };

  const handleCancel = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleCancelNewDesignation = () => {
    setNewDesignation(null);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dotnet.rapidmis.com/designationdelete/${id}`, {
        designationId: id,
      });
      setDesignationData(prevData => prevData.filter(item => item.designationId !== id));
      toast.success('Designation deleted successfully!');
    } catch (error) {
      console.error('Error deleting designation:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="bg-white p-2">
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">DESIGNATION LIST</h4>

      <div className='flex justify-end mb-2 mt-2'>
        <button className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600" onClick={handleAddNewRow}>
          <i className="fa-solid fa-plus"></i> Add New
        </button>
      </div>

      <div className="overflow-y-auto shadow-md">
        <table className="min-w-full bg-white">
          <thead className="bg-themeColor1 sticky top-0 z-10">
            <tr>
            <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Sr</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Designation Name</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Status</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Action</span></th>
            </tr>
          </thead>
          <tbody>
            {newDesignation && (
              <tr>
                <td className="py-2 px-4 border-b border-gray-300"></td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <input
                    type="text"
                    className=" border-b border-gray-500 w-full px-2 py-1"
                    name="designationName"
                    value={newDesignation.designationName}
                    onChange={handleNewDesignationInputChange}
                  />
                </td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      name="isActive"
                      checked={newDesignation.isActive}
                      onChange={handleNewDesignationInputChange}
                      className="sr-only peer"
                    />
                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <i className="fa-regular fa-floppy-disk text-lg cursor-pointer mr-2" onClick={handleSaveNewDesignation}></i>
                  <i className="fa-regular fa-circle-xmark text-lg cursor-pointer" onClick={handleCancelNewDesignation}></i>
                </td>
              </tr>
            )}
            {designationData.length === 0 ? (
              <tr>
                <td colSpan="4">
                  <div className="flex flex-col items-center justify-center my-10">
                    <Empty />
                  </div>
                </td>
              </tr>
            ) : (
              designationData.map((designationItem,index) => (
                <tr key={designationItem.designationId} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b border-gray-300">{index+1}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {editingId === designationItem.designationId ? (
                      <input
                        type="text"
                        className="py-2 px-4 border-b border-gray-300"
                        name="designationName"
                        value={updatedData[designationItem.designationId]?.designationName || ''}
                        onChange={(e) => handleInputChange(e, designationItem.designationId)}
                      />
                    ) : (
                      designationItem.designationName
                    )}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={editingId === designationItem.designationId ? updatedData[designationItem.designationId]?.isActive : designationItem.isActive}
                        onChange={(e) => handleInputChange(e, designationItem.designationId)}
                        className={`sr-only peer ${editingId === designationItem.designationId ? 'cursor-pointer' : 'cursor-default'}`}
                        disabled={editingId !== designationItem.designationId}
                      />
                      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                  {editingId === designationItem.designationId ? (
                    <>
                      <i className="fa-regular fa-floppy-disk text-lg cursor-pointer mr-2" onClick={() => handleSave(designationItem.designationId)}></i>
                      <i className="fa-regular fa-circle-xmark text-lg cursor-pointer" onClick={handleCancel}></i>
                    </>
                  ) : (
                    <>
                      <i className="fa-regular fa-pen-to-square text-lg cursor-pointer mr-2" onClick={() => handleEdit(designationItem)}></i>
                      <i className="fa-regular fa-trash-can text-lg cursor-pointer" onClick={() => handleDelete(designationItem.designationId)}></i>
                    </>
                  )}
                </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddDesignation;
