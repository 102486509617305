import React, { useState } from 'react';

const CreateBulkChallanStudent = () => {
  const [month, setMonth] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [selectAllClasses, setSelectAllClasses] = useState(false);
  const [classCheckboxes, setClassCheckboxes] = useState({
    playGroup: false,
    nursery: false,
    prep: false,
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    tenOld: false,
  });

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleIssueDateChange = (e) => {
    setIssueDate(e.target.value);
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
  };

  const handleClassCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (id === 'selectAllClasses') {
      setSelectAllClasses(checked);
      const updatedCheckboxes = {};
      for (const key in classCheckboxes) {
        updatedCheckboxes[key] = checked;
      }
      setClassCheckboxes(updatedCheckboxes);
    } else {
      setClassCheckboxes({ ...classCheckboxes, [id]: checked });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({
      month,
      issueDate,
      dueDate,
      selectedClasses: Object.keys(classCheckboxes).filter(key => classCheckboxes[key]),
    });
    // You can add further logic for form submission
  };

  return (
    <div className='p-6 min-h-screen bg-gray-100'>
      <h4 className='text-start mb-4 text-xl font-bold'>Create Bulk Challans Family Wise</h4>
      <div className="my-4">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          <div className="lg:col-span-2 mb-3 p-4 bg-white shadow rounded">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="month" className="block text-sm font-medium text-gray-700">
                  <strong>Select Month *</strong>
                </label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:border-indigo-300"
                  id="month"
                  value={month}
                  onChange={handleMonthChange}
                  required
                >
                  <option value="">Select Month</option>
                  <option value="JANUARY">JANUARY</option>
                  <option value="FEBRUARY">FEBRUARY</option>
                  <option value="MARCH">MARCH</option>
                  <option value="APRIL">APRIL</option>
                  <option value="MAY">MAY</option>
                  <option value="JUNE">JUNE</option>
                  {/* Add more months as needed */}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="issueDate" className="block text-sm font-medium text-gray-700">
                  <strong>Issue Date *</strong>
                </label>
                <input
                  type="date"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:border-indigo-300"
                  id="issueDate"
                  value={issueDate}
                  onChange={handleIssueDateChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
                  <strong>Due Date *</strong>
                </label>
                <input
                  type="date"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:border-indigo-300"
                  id="dueDate"
                  value={dueDate}
                  onChange={handleDueDateChange}
                  required
                />
              </div>
            </form>
          </div>
          <div className="lg:col-span-1 mb-3 p-4 bg-white shadow rounded">
            <h4 className="text-start mb-4 text-lg font-bold">CLASS</h4>
            <div className="mb-3">
              <div className="flex items-center mb-2">
                <input
                  className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  type="checkbox"
                  id="selectAllClasses"
                  checked={selectAllClasses}
                  onChange={handleClassCheckboxChange}
                />
                <label
                  className="ml-2 block text-sm font-medium text-gray-700"
                  htmlFor="selectAllClasses"
                >
                  Select All
                </label>
              </div>
              {Object.keys(classCheckboxes).map((key) => (
                <div className="flex items-center mb-2" key={key}>
                  <input
                    className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    type="checkbox"
                    id={key}
                    checked={classCheckboxes[key]}
                    onChange={handleClassCheckboxChange}
                  />
                  <label
                    className="ml-2 block text-sm font-medium text-gray-700"
                    htmlFor={key}
                  >
                    {key.toUpperCase()}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:col-span-1 mb-3 p-4 bg-white shadow rounded">
            <h4 className="text-start mb-4 text-lg font-bold">FEE TYPE</h4>
            <div className="mb-3">
              {["tuitionFee", "transportFee", "admissionFee", "annualFund", "registrationFee", "practicalsFee", "boardFee", "securityFee", "examinationFee", "acCharges", "stationary"].map((feeType) => (
                <div className="flex items-center mb-2" key={feeType}>
                  <input
                    className="form-check-input h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    type="checkbox"
                    id={feeType}
                  />
                  <label
                    className="ml-2 block text-sm font-medium text-gray-700"
                    htmlFor={feeType}
                  >
                    {feeType.replace(/([A-Z])/g, ' $1').toUpperCase()}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <button className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700">
        <i className="fa-solid fa-plus"></i> GENERATE CHALLANS
      </button>
    </div>
  );
};

export default CreateBulkChallanStudent;
