import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Empty } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddClass() {
  const [classData, setClassData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    axios
      .get('https://dotnet.rapidmis.com/sectiongetall', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const formattedSections = response.data.map((section) => ({
          value: section.sectionId,
          label: section.sectionName,
        }));
        setSectionData(formattedSections);
      })
      .catch((error) => console.error('Error fetching section data:', error));
  }, []);

  useEffect(() => {
    fetchClassData();
  }, []);

  const fetchClassData = () => {
    axios
      .get('https://dotnet.rapidmis.com/classgetall', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setClassData(response.data);
      })
      .catch((error) => console.error('Error fetching class data:', error));
  };

  const handleEdit = (classItem) => {
    setEditingId(classItem.classId);
    const selectedSections = classItem.sectionList.map((section) => ({
      value: section.sectionId,
      label: section.sectionDisplayName || section.sectionName, // Ensure correct display name
    }));
    setUpdatedData({
      [classItem.classId]: {
        displayName: classItem.classDisplayName, // Fix class display name
        isActive: classItem.isActive,
        sectionList: selectedSections,
      },
    });
    setModalOpen(true);
  };


  const handleSave = () => {
    if (!editingId) return;

    const sectionIdAssociate = updatedData[editingId]?.sectionList
      .map((section) => section.value)
      .join(',');

    axios
      .post(
        'https://dotnet.rapidmis.com/classupdate',
        {
          classId: editingId,
          displayName: updatedData[editingId]?.displayName,
          isActive: updatedData[editingId]?.isActive,
          sectionIdAssociate: sectionIdAssociate,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((response) => {
        setClassData((prevData) =>
          prevData.map((item) => (item.classId === editingId ? { ...item, ...updatedData[editingId], sectionIdAssociate: sectionIdAssociate } : item))
        );
        setModalOpen(false);
        setEditingId(null);
        // Show success toast
        toast.success('Class updated successfully!');
        // Refresh grid by fetching updated data
        fetchClassData();
      })
      .catch((error) => console.error('Error updating data:', error));
  };

  const handleCancel = () => {
    setModalOpen(false);
    setEditingId(null);
    setUpdatedData({});
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUpdatedData({
      ...updatedData,
      [editingId]: {
        ...updatedData[editingId],
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  const handleSectionChange = (selectedOptions) => {
    setUpdatedData({
      ...updatedData,
      [editingId]: {
        ...updatedData[editingId],
        sectionList: selectedOptions || [],
      },
    });
  };

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      height: '50px',
    }),
    control: (provided) => ({
      ...provided,
      height: '100%',
      borderRadius: '0',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0',
    }),
  };

  return (
    <div className='bg-white p-2'>
      <ToastContainer /> {/* Add ToastContainer here */}
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">CLASS LIST</h4>
      <div className="mt-4 overflow-y-auto shadow-md" style={{ maxHeight: '500px' }}>
        <table className="min-w-full bg-white">
          <thead className="bg-themeColor1 sticky top-0 z-10">
            <tr>
            <th className="py-2 px-4 border-b border-gray-300 text-white">Sr</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Class Name</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Display Name</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Sections</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Status</th>
              <th className="py-2 px-4 border-b border-gray-300 text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {classData.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <div className="flex flex-col items-center justify-center my-10">
                    <Empty />
                  </div>
                </td>
              </tr>
            ) : (
              classData.map((classItem,index) => (
                <tr key={classItem.classId} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b border-gray-300">{index+1}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{classItem.className}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{classItem.classDisplayName}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {classItem.sectionList.length > 0 ? (
                      <ul>
                        {classItem.sectionList.map((section) => (
                          <li key={section.sectionId}>{section.sectionDisplayName}</li>
                        ))}
                      </ul>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={classItem.isActive}
                        readOnly
                        className="sr-only peer"
                      />
                      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <i
                      className="fa-regular text-lg fa-pen-to-square cursor-pointer"
                      onClick={() => handleEdit(classItem)}
                    ></i>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

   

{modalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-lg z-60">
      <h4 className="text-lg font-bold mb-4 ps-2 mt-2 border-b-2 border-themeColor text-themeColor text-left">Edit Class</h4>
      <div className="px-4">
        <div className="relative mb-4">
          <input
            type="text"
            name="displayName"
            value={updatedData[editingId]?.displayName || ''}
            onChange={handleInputChange}
            className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
            placeholder=" "
            required
          />
          <label
            className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
          >
            Display Name
          </label>
        </div>
        <div className="relative mb-4">
          <Select
            options={sectionData}
            value={updatedData[editingId]?.sectionList || []}
            onChange={handleSectionChange}
            isMulti
            styles={customSelectStyles} // Apply custom styles here
            className="block w-full text-sm focus:outline-none focus:ring-0 focus:border-themeColor text-left"
            placeholder="Select Sections"
            required
          />
          <label
            className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
          >
            Sections
          </label>
        </div>
        <div className="mb-4 flex gap-2">
          <span className="mb-1">Status</span>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              name="isActive"
              checked={updatedData[editingId]?.isActive || false}
              onChange={handleInputChange}
              className="sr-only peer"
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      
      </div>
      <div className="flex justify-end p-2 mt-4 bg-gray-100" style={{borderBottomLeftRadius:'10px', borderBottomRightRadius:'10px'}}>
        <button onClick={handleSave} className="bg-themeColor text-white py-2 px-4 rounded-lg hover:bg-lightThemeColor">
          Save
        </button>
        <button onClick={handleCancel} className="bg-gray-400 text-white py-2 px-4 rounded-lg ml-2 hover:bg-gray-500">
          Cancel
        </button>
      </div>
    </div>
  </div>
)}
</div>
);
}

export default AddClass;