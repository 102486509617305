import React, { useState } from 'react';
import AddStudentForm from '../components/AddStudentForm';
import NewStudentFeeManagement from './NewStudentFeeManagement';

const AddStudent = () => {
  const [activeTab, setActiveTab] = useState('form');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="bg-white p-2">
      {/* Tab Navigation */}
      <ul className="flex border-b border-themeColor1 mb-2">
        <li className="mr-2">
          <button
            className={`px-4 py-2 text-sm font-semibold ${
              activeTab === 'form' ? 'border-b-4 border-themeColor1 text-themeColor' : 'text-themeColor1'
            }`}
            onClick={() => handleTabChange('form')}
          >
            <h5 className="text-start text-themeColor1 font-bold">ADD STUDENT</h5>
          </button>
        </li>
        <li className="mr-2">
          <button
            className={`px-4 py-2 text-sm font-semibold ${
              activeTab === 'list' ? 'border-b-4 border-themeColor text-themeColor' : 'text-themeColor1'
            }`}
            onClick={() => handleTabChange('list')}
          >
            <h5 className="text-start text-themeColor1 font-bold">ADD FEE</h5>
          </button>
        </li>
      </ul>

      {/* Render the active tab's content */}
      <div className="bg-white">
        {activeTab === 'form' ? <AddStudentForm /> : <NewStudentFeeManagement />}
      </div>
    </div>
  );
};

export default AddStudent;
