import React, { useState } from 'react';

const CreateBulkChallanFamily = () => {
  const [month, setMonth] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [selectAllClasses, setSelectAllClasses] = useState(false);
  const [classCheckboxes, setClassCheckboxes] = useState({
    playGroup: false,
    nursery: false,
    prep: false,
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    tenOld: false,
  });

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleIssueDateChange = (e) => {
    setIssueDate(e.target.value);
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
  };

  const handleClassCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (id === 'selectAllClasses') {
      setSelectAllClasses(checked);
      const updatedCheckboxes = {};
      for (const key in classCheckboxes) {
        updatedCheckboxes[key] = checked;
      }
      setClassCheckboxes(updatedCheckboxes);
    } else {
      setClassCheckboxes({ ...classCheckboxes, [id]: checked });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      month,
      issueDate,
      dueDate,
      selectedClasses: Object.keys(classCheckboxes).filter(key => classCheckboxes[key]),
    });
  };

  return (
    <div className='p-6 min-h-screen bg-gray-100'>
      <h4 className='text-2xl font-bold mb-4'>Create Bulk Challans Family Wise</h4>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Month, Issue Date, Due Date Section */}
        <div className="bg-white p-4 rounded shadow">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="month" className="block text-sm font-semibold mb-1">
                Select Month *
              </label>
              <select
                className="w-full p-2 border border-gray-300 rounded"
                id="month"
                value={month}
                onChange={handleMonthChange}
                required
              >
                <option value="">Select Month</option>
                <option value="JANUARY">JANUARY</option>
                <option value="FEBRUARY">FEBRUARY</option>
                <option value="MARCH">MARCH</option>
                <option value="APRIL">APRIL</option>
                <option value="MAY">MAY</option>
                <option value="JUNE">JUNE</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="issueDate" className="block text-sm font-semibold mb-1">
                Issue Date *
              </label>
              <input
                type="date"
                className="w-full p-2 border border-gray-300 rounded"
                id="issueDate"
                value={issueDate}
                onChange={handleIssueDateChange}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="dueDate" className="block text-sm font-semibold mb-1">
                Due Date *
              </label>
              <input
                type="date"
                className="w-full p-2 border border-gray-300 rounded"
                id="dueDate"
                value={dueDate}
                onChange={handleDueDateChange}
                required
              />
            </div>
          </form>
        </div>

        {/* Class Section */}
        <div className="bg-white p-4 rounded shadow">
          <h4 className="text-lg font-bold mb-4">CLASS</h4>
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="selectAllClasses"
                checked={selectAllClasses}
                onChange={handleClassCheckboxChange}
              />
              <label
                className="ml-2 font-semibold"
                htmlFor="selectAllClasses"
              >
                Select All
              </label>
            </div>
            {Object.keys(classCheckboxes).map((key) => (
              <div className="flex items-center mb-2" key={key}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={key}
                  checked={classCheckboxes[key]}
                  onChange={handleClassCheckboxChange}
                />
                <label
                  className="ml-2"
                  htmlFor={key}
                >
                  {key.toUpperCase()}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Fee Type Section */}
        <div className="bg-white p-4 rounded shadow">
          <h4 className="text-lg font-bold mb-4">FEE TYPE</h4>
          <div className="mb-4">
            {['tuitionFee', 'transportFee', 'admissionFee', 'annualFund', 'registrationFee', 'practicalsFee', 'boardFee', 'securityFee', 'examinationFee', 'acCharges', 'stationary'].map((feeType) => (
              <div className="flex items-center mb-2" key={feeType}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={feeType}
                />
                <label
                  className="ml-2"
                  htmlFor={feeType}
                >
                  {feeType.replace(/([A-Z])/g, ' $1').toUpperCase()}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <button className="mt-6 bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition">
        <i className="fa-solid fa-plus"></i> GENERATE CHALLANS
      </button>
    </div>
  );
};

export default CreateBulkChallanFamily;
