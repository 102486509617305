import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Empty } from 'antd';

function AddSection() {
  const [sectionData, setSectionData] = useState([]);
  const [newSection, setNewSection] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = async () => {
    try {
      const response = await axios.get('https://dotnet.rapidmis.com/sectiongetall', {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setSectionData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAddNewRow = () => {
    const newRow = { sectionId: null, sectionName: '', displayName: '', isActive: true };
    setNewSection(newRow);
  };

  const handleSaveNewSection = async () => {
    if (newSection.sectionName.trim() === '' || newSection.displayName.trim() === '') {
      alert('Please enter both Section Name and Display Name');
      return;
    }

    try {
      const response = await axios.post('https://dotnet.rapidmis.com/sectioncreate', newSection, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('New section added:', response.data);
      setSectionData(prevData => [...prevData, response.data]);
      setNewSection(null);
      toast.success('Section added successfully!'); // Show success notification
    } catch (error) {
      console.error('Error adding new section:', error);
    }
  };

  const handleNewSectionInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSection({
      ...newSection,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleInputChange = (e, id) => {
    const { name, value, type, checked } = e.target;
    setUpdatedData({
      ...updatedData,
      [id]: {
        ...updatedData[id],
        [name]: type === 'checkbox' ? checked : value
      }
    });
  };

  const handleEdit = (sectionItem) => {
    setEditingId(sectionItem.sectionId);
    setUpdatedData({
      [sectionItem.sectionId]: {
        sectionName: sectionItem.sectionName, // Editable
        displayName: sectionItem.displayName,
        isActive: sectionItem.isActive
      }
    });
  };

  const handleSave = async (id) => {
    const updatedSection = updatedData[id];

    try {
      const response = await axios.post('https://dotnet.rapidmis.com/sectionupdate', {
        sectionId: id,
        displayName: updatedSection.displayName,
        sectionName: updatedSection.sectionName || '', // Allow empty section name
        isActive: updatedSection.isActive // Save the current state of the toggle
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Updated data:', response.data);
      setSectionData(prevData => prevData.map(item => item.sectionId === id ? { ...item, ...updatedSection } : item));
      setEditingId(null);
      toast.success('Section updated successfully!'); // Show success notification
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleCancelNewSection = () => {
    setNewSection(null);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://dotnet.rapidmis.com/sectiondelete/${id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Delete response:', response.data);
      setSectionData(prevData => prevData.filter(item => item.sectionId !== id));
      toast.success('Section deleted successfully!'); // Show success notification
    } catch (error) {
      console.error('Error deleting section:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="bg-white p-2">
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">SECTION LIST</h4>

      {/* Add New Button */}
      <div className='flex justify-end mb-2 mt-2'>
        <button className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600" onClick={handleAddNewRow}>
          <i className="fa-solid fa-plus"></i> Add New
        </button>
      </div>

      {/* Table */}
      <div className="overflow-y-auto shadow-md">
        <table className="min-w-full bg-white">
          <thead className="bg-themeColor sticky top-0 z-10">
            <tr>
            <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Sr</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Section Name</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Display Name</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Status</span></th>
              <th className="py-2 px-4 border-b border-gray-300 text-white"><span>Action</span></th>
            </tr>
          </thead>
          <tbody>
            {/* New Section Row */}
            {newSection && (
              <tr>
                <td className="py-2 px-4 border-b border-gray-300">
                  <input
                    type="text"
                    className="border-b border-gray-500 w-full px-2 py-1"
                    name="sectionName"
                    value={newSection.sectionName}
                    onChange={handleNewSectionInputChange}
                  />
                </td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <input
                    type="text"
                    className="border-b border-gray-500 w-full px-2 py-1"
                    name="displayName"
                    value={newSection.displayName}
                    onChange={handleNewSectionInputChange}
                  />
                </td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      name="isActive"
                      checked={newSection.isActive}
                      onChange={handleNewSectionInputChange}
                      className="sr-only peer"
                    />
                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <>
                    <i className="fa-regular fa-floppy-disk text-lg cursor-pointer mr-2" onClick={handleSaveNewSection}></i>
                    <i className="fa-regular fa-circle-xmark text-lg cursor-pointer" onClick={handleCancelNewSection}></i>
                  </>
                </td>
              </tr>
            )}
            {sectionData.length === 0 ? (
              <tr>
                <td colSpan="5">
                  <div className="flex flex-col items-center justify-center my-10">
                    <Empty />
                  </div>
                </td>
              </tr>
            ) : (
              sectionData.map((sectionItem,index) => (
                <tr key={sectionItem.sectionId} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b border-gray-300">{index + 1}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {editingId === sectionItem.sectionId ? (
                      <input
                        type="text"
                        className="py-2 px-4 border-b border-gray-300"
                        name="sectionName"
                        value={updatedData[sectionItem.sectionId]?.sectionName || ''} // Allow section name to be empty
                        onChange={(e) => handleInputChange(e, sectionItem.sectionId)}
                      />
                    ) : (
                      sectionItem.sectionName
                    )}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {editingId === sectionItem.sectionId ? (
                      <input
                        type="text"
                        className="py-2 px-4 border-b border-gray-300"
                        name="displayName"
                        value={updatedData[sectionItem.sectionId]?.displayName || ''}
                        onChange={(e) => handleInputChange(e, sectionItem.sectionId)}
                      />
                    ) : (
                      sectionItem.displayName
                    )}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {editingId === sectionItem.sectionId ? (
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          name="isActive"
                          checked={updatedData[sectionItem.sectionId]?.isActive}
                          onChange={(e) => handleInputChange(e, sectionItem.sectionId)} // Now it's editable
                          className="sr-only peer"
                        />
                        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    ) : (
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={sectionItem.isActive}
                          readOnly
                          className="sr-only peer"
                        />
                        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    )}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {editingId === sectionItem.sectionId ? (
                      <>
                        <i className="fa-regular fa-floppy-disk text-lg cursor-pointer mr-2" onClick={() => handleSave(sectionItem.sectionId)}></i>
                        <i className="fa-regular fa-circle-xmark text-lg cursor-pointer" onClick={handleCancel}></i>
                      </>
                    ) : (
                      <>
                        <i className="fa-regular fa-pen-to-square text-lg cursor-pointer mr-2" onClick={() => handleEdit(sectionItem)}></i>
                        <i className="fa-regular fa-trash-can text-lg cursor-pointer" onClick={() => handleDelete(sectionItem.sectionId)}></i>
                      </>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  );
}

export default AddSection;
