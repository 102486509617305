import React from 'react'

const FeeHistoryForReceiving = () => {
    const theadThStyle = {
        borderBottom: '1px solid #C2C2C2',
        paddingBottom: '0',
        backgroundColor: '#FF5F00',
        color: 'black',  // To make the text readable on the dark background
      };
    
      const thSpanStyle = {
        borderBottom: '2px solid #102C57',
        display: 'inline-block',
        padding: '0 0px',
        paddingBottom: '5px',
      };
  return (
    <div style={{ maxHeight: "calc(100vh - 180px)", overflowY: "auto" }}>
        <div className="">
        <h3  className='p-2 rounded' style={{backgroundColor:"#102C57", color:"white"}}><strong>HISTORY</strong></h3>
        <table className="table table-bordered mb-0">
        
        <thead>
            <tr>
                <th style={theadThStyle}>
                    <span style={thSpanStyle}>Month</span>
                </th>
                <th style={theadThStyle}>
                    <span style={thSpanStyle}>Total Bill</span>
                </th>
                <th style={theadThStyle}>
                    <span style={thSpanStyle}>Total Receive</span>
                </th>
                <th style={theadThStyle}>
                    <span style={thSpanStyle}>Balance</span>
                </th>
                <th style={theadThStyle}>
                    <span style={thSpanStyle}>Date</span>
                </th>
            </tr>
        </thead>
          <tbody>
            <tr>
              <td scope="row">June</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">May</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Apr</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Mar</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Feb</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Jan</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">June</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">May</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Apr</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Mar</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Feb</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            <tr>
              <td scope="row">Jan</td>
              <td>3450</td>
              <td>3000</td>
              <td>450</td>
              <td>6/14/2024</td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FeeHistoryForReceiving