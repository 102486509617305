import React, { useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { ToastContainer, toast } from 'react-toastify';

function StaffAttendancePage() {
  const [designation, setDesignation] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDesignation, setSelectedDesignation] =useState('');
  const [maxDate, setMaxDate] = useState('');
  const [attendance, setAttendance] = useState({
    employeeStatuses: [],
    isNewAttendance: false, // Added default state for new or existing attendance
  });
  useEffect(() => {
    fetch('https://dotnet.rapidmis.com/employeeattendancelookup')
      .then((response) => response.json())
      .then((data) => {
        setDesignation(data.designationList);
      })
      .catch((error) => console.error('Error fetching lookup data:', error));
  }, []);

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    setMaxDate(`${yyyy}-${mm}-${dd}`);
  }, []);



  const handleSearch = () => {
    if (!selectedDesignation || !selectedDate) {
      toast.error('Please select Designation and date.');
      return;
    }
  
    const apiUrl = `https://dotnet.rapidmis.com/employeeattendancebyfilter?DesignationId=${selectedDesignation}&AttendanceDate=${selectedDate}`;
    
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setEmployees(data);
  
          // Check if the status is null for any employee
          const isNewAttendance = data.some(employee => employee.status === null);
  
          setAttendance({
            employeeStatuses: data.map((employee) => ({
              present: employee.status === 'Present',
              absent: employee.status === 'Absent',
              late: employee.status === 'Late',
              leave: employee.status === 'Leave',
            })),
            isNewAttendance, // Set based on null status check
          });
        } else {
          console.warn('Unexpected data format:', data);
          setEmployees([]);
          setAttendance({ employeeStatus: [], isNewAttendance: false });
        }
      })
      .catch((error) => {
        console.error('Error fetching employee attendance:', error);
        alert('An error occurred while fetching Employee attendance.');
      });
  };
  
const handleDesignationChange =(e) =>{
  setSelectedDesignation(e.target.value);
}

  const handleEmployeeChange = (index, type) => {
    setAttendance((prevState) => {
      const updatedStatuses = [...prevState.employeeStatuses];
      updatedStatuses[index] = {
        present: type === 'present',
        absent: type === 'absent',
        late: type === 'late',
        leave: type === 'leave',
      };
      return {
        ...prevState,
        employeeStatuses: updatedStatuses,
      };
    });
  };

  const handleHeaderChange = (type) => {
    setAttendance((prevState) => ({
      ...prevState,
      employeeStatuses: prevState.employeeStatuses.map(() => ({
        present: type === 'present',
        absent: type === 'absent',
        late: type === 'late',
        leave: type === 'leave',
      })),
    }));
  };

  const handleSave = () => {
    const attendanceDetailList = employees.map((employee, index) => {
      const employeeStatus = attendance.employeeStatuses[index];
      let status = null; // Initialize as null
      
      // Assign the correct status based on employee attendance
      if (employeeStatus.present) {
        status = 'Present';
      } else if (employeeStatus.absent) {
        status = 'Absent';
      } else if (employeeStatus.late) {
        status = 'Late';
      } else if (employeeStatus.leave) {
        status = 'Leave';
      }
      
      return {
        instanceId: employee.employeeId,
        status: status, // Now ensures status is not an empty string
        remarks: '',
      };
    });
  
    const attendanceData = {
      attendanceTypeId: 2,
      attendanceDate: selectedDate,
      attendanceDetailList: attendanceDetailList,
    };
  
    // Choose the API based on isNewAttendance flag
    const apiUrl = attendance.isNewAttendance
      ? 'https://dotnet.rapidmis.com/attendancecreate'
      : 'https://dotnet.rapidmis.com/attendanceupdate';
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(attendanceData),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Attendance saved successfully');
        } else {
          toast.error('Failed to save attendance');
        }
      })
      .catch((error) => {
        console.error('Error saving attendance:', error);
        alert('An error occurred while saving attendance');
      });
  };
  
  
  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const day = selectedDate.getDay();
    if (day === 0) {
      toast.error("Sunday is not allowed.");
      e.target.value = '';
    } else {
      setSelectedDate(e.target.value);
    }
  };

  return (
    <div className='space-y-4'>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    
      <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">Filters</h4>
        <div className='flex flex-col md:flex-row md:space-x-2 my-2'>
          <div className='flex-grow md:w-1/4'>
          <div className="relative">
              <input
                type="date"
                className="block w-full pr-3 pl-3 py-2 text-sm text-gray-900 bg-gray-50 border border-gray-300"
                value={selectedDate}
                onChange={handleDateChange}
                max={maxDate}
              />
            </div>
          </div>
          <div className='flex-grow md:w-1/4'>
            <div className='relative'>
              <MenuOutlined className='absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none' />
              <select
                className='block w-full border border-gray-300 py-2 pl-10 pr-3 bg-gray-50'
                value={selectedDesignation}
                onChange={handleDesignationChange}
              >
                <option value=''>Select Designation</option>
                {designation.map((cls) => (
                  <option key={cls.designationId} value={cls.designationId}>
                    {cls.designationName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex-grow md:w-1/4'>
            
          </div>
          <div className='flex-grow md:w-1/4'>
            <button className="bg-blue-500 w-full text-white py-2 hover:bg-blue-600" onClick={handleSearch}>
              <i className="fa-solid fa-filter"></i> Filter
            </button>
          </div>
        </div>
      </div>

      <div className='bg-white p-2'>
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Student Attendance</h4>
        <div className="overflow-x-auto shadow-md">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-themeColor1">
                <th className="px-4 py-2 text-left text-white">Name</th>
                <th className="px-4 py-2 text-white">Reg No</th>
                <th className="px-4 py-2 text-white">Family Id</th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('present')}
                  /> Present
                </th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('absent')}
                  /> Absent
                </th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('late')}
                  /> Late
                </th>
                <th className="px-4 py-2 text-center text-white">
                  <input
                    type="radio"
                    name="attendanceAll"
                    onChange={() => handleHeaderChange('leave')}
                  /> Leave
                </th>
              </tr>
            </thead>
            <tbody>
              {employees.length === 0 ? (
                <tr>
                <td colSpan="7" className="border-b border-gray-300 px-2 py-3 text-center">
                  <Empty />
                </td>
              </tr>
              ) : (
                employees.map((employee, index) => (
                  <tr key={employee.employeeId}>
                    <td className="px-4 py-2">{employee.employeeName}</td>
                    <td className="px-4 py-2">{employee.regNo}</td>
                    <td className="px-4 py-2">{employee.familyId}</td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`present${index}`}
                        value="present"
                        checked={attendance.employeeStatuses[index]?.present || false}
                        onChange={() => handleEmployeeChange(index, 'present')}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`absent${index}`}
                        value="absent"
                        checked={attendance.employeeStatuses[index]?.absent || false}
                        onChange={() => handleEmployeeChange(index, 'absent')}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`late${index}`}
                        value="late"
                        checked={attendance.employeeStatuses[index]?.late || false}
                        onChange={() => handleEmployeeChange(index, 'late')}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`attendanceStatus${index}`}
                        id={`leave${index}`}
                        value="leave"
                        checked={attendance.employeeStatuses[index]?.leave || false}
                        onChange={() => handleEmployeeChange(index, 'leave')}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/* Save Button */}
        <div className='flex justify-end mb-2 mt-2 text-center'>
          <button className="bg-blue-500 text-white px-6 py-2 hover:bg-blue-600" onClick={handleSave}>
            <i className="fa-regular fa-floppy-disk me-1"></i> Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default StaffAttendancePage;
