import React, { useState, useEffect } from 'react';
import CreateStudentChallan from '../components/CreateStudentChallan';
import CreateFamilyChallan from '../components/CreateFamilyChallan';
import CreateBulkChallanFamily from '../components/CreateBulkChallanFamily';
import CreateBulkChallanStudent from '../components/CreateBulkChallanStudent';

const FeeBillPage = () => {
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const handlePopState = (event) => {
      setCurrentPage(event.state ? event.state.page : 'home');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const navigate = (page) => {
    window.history.pushState({ page }, '', page);
    setCurrentPage(page);
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'bulkChallanFamily':
        return <CreateBulkChallanFamily />;
      case 'bulkChallanStudent':
        return <CreateBulkChallanStudent />;
      case 'singleStudentChallan':
        return <CreateStudentChallan />;
      case 'familyChallan':
        return <CreateFamilyChallan />;
      default:
        return (
          <div className="flex flex-wrap justify-center">
            {/* Card 1 - Bulk Challans (All Classes/Family Wise) */}
            <div className="bg-white shadow-lg rounded-lg border border-info w-full sm:w-1/2 lg:w-1/4 m-2 cursor-pointer" onClick={() => navigate('bulkChallanFamily')}>
              <div className="p-4">
                <div className="flex items-center">
                  <div>
                    <h4 className="mb-1 text-info font-bold">Create Bulk Challans</h4>
                    <p className="text-danger text-sm">All Classes/Family Wise</p>
                  </div>
                  <div className="bg-gradient-to-r from-blue-400 to-green-400 rounded-full p-2 ml-auto">
                    <i className="fa-solid fa-bullhorn text-white"></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 - Bulk Challans (All Classes/Student Wise) */}
            <div className="bg-white shadow-lg rounded-lg border border-info w-full sm:w-1/2 lg:w-1/4 m-2 cursor-pointer" onClick={() => navigate('bulkChallanStudent')}>
              <div className="p-4">
                <div className="flex items-center">
                  <div>
                    <h4 className="mb-1 text-info font-bold">Create Bulk Challans</h4>
                    <p className="text-danger text-sm">All Classes/Student Wise</p>
                  </div>
                  <div className="bg-gradient-to-r from-blue-400 to-green-400 rounded-full p-2 ml-auto">
                    <i className="fa-solid fa-bullhorn text-white"></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 3 - Single Student Challan */}
            <div className="bg-white shadow-lg rounded-lg border border-info w-full sm:w-1/2 lg:w-1/4 m-2 cursor-pointer" onClick={() => navigate('singleStudentChallan')}>
              <div className="p-4">
                <div className="flex items-center">
                  <div>
                    <h4 className="mb-1 text-info font-bold">Single Student Challan</h4>
                  </div>
                  <div className="bg-gradient-to-r from-blue-400 to-green-400 rounded-full p-2 ml-auto">
                    <i className="fa-solid fa-bullhorn text-white"></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 4 - Single Family Challan */}
            <div className="bg-white shadow-lg rounded-lg border border-info w-full sm:w-1/2 lg:w-1/4 m-2 cursor-pointer" onClick={() => navigate('familyChallan')}>
              <div className="p-4">
                <div className="flex items-center">
                  <div>
                    <h4 className="mb-1 text-info font-bold">Single Family Challan</h4>
                  </div>
                  <div className="bg-gradient-to-r from-blue-400 to-green-400 rounded-full p-2 ml-auto">
                    <i className="fa-solid fa-bullhorn text-white"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return renderPage();
};

export default FeeBillPage;
