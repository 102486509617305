import React, { useState } from 'react';
import { Empty } from 'antd';
const FamilySummary = () => {
  const [familyId, setFamilyId] = useState('');
  const [familyData, setFamilyData] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch data function
  const fetchFamilyData = async (id) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`https://dotnet.rapidmis.com/studentsummary/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch family data');
      }
      const data = await response.json();
      setFamilyData(data);
      setSelectedStudent(data[0]); // Set the first student as the default selected
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle input change and Enter key press
  const handleInputChange = (e) => {
    setFamilyId(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchFamilyData(familyId);
    }
  };

  // Handle student row click
  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  return (
    <div className="bg-white p-2">
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Family Summary</h4>

      {/* Main Upper */}
      <div className='flex gap-2'>
        {/* Left Portion */}
        <div className='w-4/6 space-y-2'>
          {/* Upper Portion */}
          <div className='w-full mx-auto bg-gray-50 p-2 border-2 border-gray-100'>
            <div className='flex justify-between mb-4'>
              <p className='m-0'>
                <strong>Family Id:</strong> 
                <input
                      type="text"
                      className="ms-2 py-1 px-2 border-b border-gray-400"
                      value={familyId}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress} // Triggers fetch on Enter key
                      name="familyId"
                    />
              </p>
              <p className='m-0'>
                <strong>Contact:</strong> {selectedStudent?.fatherMobile1 || '0300-0000000'}
              </p>
            </div>
            <div className='flex justify-between mb-4'>
              <p className='m-0'>
                <strong>Father Name:</strong> {selectedStudent?.fatherName || 'Father Name'}
              </p>
              <p className='m-0'>
                <strong>WhatsApp:</strong> {selectedStudent?.whatsAppNo || '0300-0000000'}
              </p>
            </div>
            <div className='flex justify-between mb-4'>
              <p className='m-0'>
                <strong>Caste:</strong> {selectedStudent?.caste || 'N/A'}
              </p>
              <p className='m-0'>
                <strong>Religion:</strong> {selectedStudent?.religion || 'N/A'}
              </p>
            </div>
            <div className='flex justify-between mb-4'>
              <p className='m-0'>
                <strong>Strength:</strong> {selectedStudent?.strength || 'N/A'}
              </p>
            </div>
            <div className='flex justify-between mb-4'>
              <p className='m-0'>
                <strong>Gaurdian Name:</strong> {selectedStudent?.guardianName || 'N/A'}
              </p>
              <p className='m-0'>
                <strong>Gaurdian Contact:</strong> {selectedStudent?.guardianMobileNo || 'N/A'}
              </p>
            </div>
            <div className='flex justify-between mb-4'>
              <p className='m-0'>
                <strong>Address:</strong> {selectedStudent?.address1 || 'Home'}, {selectedStudent?.address2 || 'Street'}
              </p>
            </div>
          </div>

          {/* Below Upper */}
          <div className='w-full mx-auto bg-gray-50 border-gray-100'>
            <table className='table-auto w-full border-collapse'>
              <thead className='bg-themeColor1'>
                <tr>
                  <th className='px-4 py-2 text-left text-white'>Name</th>
                  <th className='px-4 py-2 text-left text-white'>REG</th>
                  <th className='px-4 py-2 text-left text-white'>Class</th>
                  <th className='px-4 py-2 text-center text-white'>Status</th>
                </tr>
              </thead>
              <tbody>
                {familyData?.map((student) => (
                  <tr
                    key={student.studentId}
                    className='border-b hover:bg-gray-100 cursor-pointer'
                    onClick={() => handleStudentClick(student)} // Set the selected student
                  >
                    <td className='px-4 py-2 text-left'>{student.studentName}</td>
                    <td className='px-4 py-2 text-left'>{student.regNo}</td>
                    <td className='px-4 py-2 text-left'>{student.className}</td>
                    <td className="border-b border-gray-300 px-2 py-3 text-gray-500">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input 
                          type="checkbox" 
                          className="sr-only peer" 
                          defaultChecked={student.isActive}
                          disabled
                        />
                        <div className="w-9 h-5 bg-gray-300 rounded-full peer-checked:bg-green-500 peer-focus:ring-4 peer-focus:ring-green-300 transition-all duration-300"></div>
                        <div className="absolute left-[2px] top-[2px] w-4 h-4 bg-white rounded-full border border-gray-300 peer-checked:translate-x-full peer-checked:border-green-500 transition-all duration-300"></div>
                      </label>
                    </td>
                  </tr>
                )) || (
                  <tr>
                <td colSpan="4" className="border-b border-gray-300 px-2 py-3 text-center">
                  <Empty />
                </td>
              </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Right Portion (Selected Student Details) */}
        <div className='w-2/6 bg-gray-50 border-2 border-gray-100'>
          <div className='text-center my-4'>
            <img
              src='https://bootdey.com/img/Content/avatar/avatar6.png'
              alt='Profile Avatar'
              className='rounded-full w-20 h-20 mx-auto'
            />
          </div>
          {selectedStudent && (
            <div className='overflow-x-auto'>
              <table className='table-auto w-full border-collapse'>
                <tbody>
                  <tr className='border-b'>
                    <td className='px-4 py-2 font-medium text-left'>Name</td>
                    <td className='px-4 py-2 text-right'>{selectedStudent?.studentName}</td>
                  </tr>
                  <tr className='border-b'>
                    <td className='px-4 py-2 font-medium text-left'>Reg No</td>
                    <td className='px-4 py-2 text-right'>{selectedStudent.regNo}</td>
                  </tr>
                  <tr className='border-b'>
                    <td className='px-4 py-2 font-medium text-left'>Class</td>
                    <td className='px-4 py-2 text-right'>{selectedStudent.classDisplayName}</td>
                  </tr>
                  <tr className='border-b'>
                    <td className='px-4 py-2 font-medium text-left'>Section</td>
                    <td className='px-4 py-2 text-right'>{selectedStudent.sectionDisplayName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {selectedStudent && (
            <div className='overflow-x-auto'>
              <table className='table-auto w-full border-collapse'>
              <tbody>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Name</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.studentName || 'N/A'}</td>
                </tr>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Reg No</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.regNo || 'N/A'}</td>
                </tr>
                {/* Additional details can go here */}
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Father Name</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.fatherName || 'N/A'}</td>
                </tr>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Gender</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.gender || 'N/A'}</td>
                </tr>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Medium</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.mediumName || 'N/A'}</td>
                </tr>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Admission Date</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.admDate || 'N/A'}</td>
                </tr>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Subject Optional</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.subjectOptional || 'N/A'}</td>
                </tr>
                <tr className='border-b'>
                  <td className='px-4 py-2 font-medium text-left'>Medical Problem</td>
                  <td className='px-4 py-2 text-right'>{selectedStudent?.medicalProblem || 'N/A'}</td>
                </tr>
              </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FamilySummary;
