import React, { useState } from 'react';
import { staffResult } from '../../api/StaffResult';

const getProgressBarColor = (percentage) => {
  if (percentage >= 70) {
    return 'bg-green-500'; // Green for high percentage
  } else if (percentage >= 50) {
    return 'bg-yellow-500'; // Yellow for medium percentage
  } else {
    return 'bg-red-500'; // Red for low percentage
  }
};

const StaffResult = () => {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [selectedStaff, setSelectedStaff] = useState(null); // State to hold selected staff

  const data = Array.isArray(staffResult) ? staffResult : [];

  const handleShowModal = (staff) => {
    setSelectedStaff(staff);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedStaff(null);
  };

  return (
    <div className="bg-white p-2">
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Staff Result</h4>
      <ul className="list-group mx-2 overflow-hidden">
        {data.map((staff) => (
          <li key={staff.id} className="flex items-center text-start py-4 px-4">
            <span className="flex-grow text-lg font-semibold">{staff.name}</span>
            <div className="flex items-center pull-left me-5" style={{ width: '70%' }}>
              <div className="relative flex-grow mx-3">
                <div
                  className={`absolute h-3 rounded-full ${getProgressBarColor(staff.result)}`}
                  style={{ width: `${staff.result}%`, height: '100%' }}
                  aria-valuenow={staff.result}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
                <div className="h-3 bg-gray-200 rounded-full"></div>
              </div>
              <span className="font-bold">{staff.result}%</span>
            </div>
            <button
              className="ml-3 px-3 py-1 bg-blue-500 text-white hover:bg-blue-600 transition duration-200"
              onClick={() => handleShowModal(staff)}
            >
              <i class="fa-regular fa-eye me-2"></i>View
            </button>
          </li>
        ))}
      </ul>

      {/* Modal for Staff Detail */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white shadow-lg w-11/12 md:w-1/3">
            <div className="p-4 border-b flex justify-between items-center">
              <h4 className="text-xl font-semibold">{selectedStaff.name}</h4>
              <button className="text-gray-500 text-lg" onClick={handleCloseModal}>
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
            <div className="">
              {selectedStaff ? (
                <>
                  <table className="table-auto w-full border">
                    <thead>
                      <tr className="text-center bg-gray-100">
                        <th className="px-4 py-2 border">Subject</th>
                        <th className="px-4 py-2 border">Class</th>
                        <th className="px-4 py-2 border">Score (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedStaff.subjects.map((subject, index) => (
                        <tr key={index} className="text-center hover:bg-gray-50">
                          <td className="border px-4 py-2">{subject.title}</td>
                          <td className="border px-4 py-2">{subject.class}</td>
                          <td className="border px-4 py-2">{subject.score}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <p className="text-center">No details available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffResult;
