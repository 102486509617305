import React from 'react';

const FeeDetailForReceiving = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const tableContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
    width: '100%',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #C2C2C2',
    padding: '8px',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid #C2C2C2',
    padding: '8px',
    textAlign: 'center',
  };

  return (
    <div style={containerStyle}>
      <div className="p-2" style={{ width: '100%' }}>
        <h3 className='p-2 rounded' style={{backgroundColor:"#102C57", color:"white"}}><strong>Voucher</strong></h3>
        <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <tbody>
              <tr>
                <th style={thStyle} scope="row">Tuition Fee:</th>
                <td style={tdStyle}></td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">Discount:</th>
                <td style={tdStyle}>154</td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">Arrears:</th>
                <td style={tdStyle}>121</td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">PFuns:</th>
                <td style={tdStyle}></td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">Stationary:</th>
                <td style={tdStyle}></td>
              </tr>
            </tbody>
          </table>
          <table style={tableStyle}>
            <tbody>
              
              <tr>
                <th style={thStyle} scope="row">Conveyance:</th>
                <td style={tdStyle}></td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">Attendance Fine:</th>
                <td style={tdStyle}></td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">Total Dues:</th>
                <td style={tdStyle}>5464</td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">Late Fee Fine:</th>
                <td style={tdStyle}>262+6</td>
              </tr>
              <tr>
                <th style={thStyle} scope="row">After Due Date:</th>
                <td style={tdStyle}>515</td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <table style={tableStyle} className='mt-2'>
            <tbody>
              <tr>
                <th style={thStyle} scope="row">Due Date:</th>
                <td style={tdStyle}></td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  );
};

export default FeeDetailForReceiving;
