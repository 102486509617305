import React from 'react';
import FeeDetailAndHistory from '../components/FeeDetailAndHistory';
import FeeReceivingForm from '../components/FeeReceivingForm';

function FeeReceiving() {
  return (
    <div className="relative">
      <div className="">
        <FeeDetailAndHistory />
      </div>
      <button
        className="absolute bottom-3 right-3 bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow-md hover:bg-blue-600 focus:outline-none"
        data-bs-toggle="modal" 
        data-bs-target="#feeReceivingModal"
      >
        RECEIVING
      </button>
      <div className="modal fade" id="feeReceivingModal" tabIndex="-1" aria-labelledby="feeReceivingModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" centered>
        <div className="modal-dialog modal-dialog-centered max-w-3xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="text-lg text-[#102C57]" id="feeReceivingModalLabel">Receiving Form</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body overflow-auto">
              <FeeReceivingForm />
            </div>
            <div className="modal-footer">
              <button type="button" className="bg-gray-500 text-white font-semibold py-2 px-4 rounded hover:bg-gray-600" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeeReceiving;
