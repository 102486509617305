import React from 'react';
import { result } from '../../api/Results';

const getBgClass = (percentage) => {
  if (percentage < 50) return 'bg-red-500 text-white';
  if (percentage >= 51 && percentage <= 69) return 'bg-yellow-300';
  return '';
};

const ViewResults = () => {
  // Calculate totals
  const examTitles = result[0].exams.map(exam => exam.title);
  const totals = examTitles.map(() => 0);
  const classCounts = result.length;

  result.forEach(classResult => {
    classResult.exams.forEach((exam, index) => {
      totals[index] += exam.percentage;
    });
  });

  const averagePercentages = totals.map(total => (total / classCounts).toFixed(2));
  const totalPercentage = (
    result.reduce((acc, classResult) => acc + Number(classResult.totalPercentage), 0) / classCounts
  ).toFixed(2);

  return (
    <div className="bg-white p-2">
    <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">Class List</h4>
      <table className="min-w-full table-auto border-b border-gray-200 shadow-md">
        <thead className="bg-themeColor text-white">
          <tr className='text-center'>
            <th className="px-4 py-2">Class</th>
            {result[0].exams.map((exam, index) => (
              <th key={index} className="px-4 py-2">{exam.title}</th>
            ))}
            <th className="px-4 py-2">Total Percentage</th>
          </tr>
        </thead>
        <tbody>
          {result.map((classResult) => (
            <tr key={classResult.id} className="border-b border-gray-300">
              <td className="px-4 py-2">{classResult.class}</td>
              {classResult.exams.map((exam, index) => (
                <td
                  key={index}
                  className={`px-4 py-2 ${getBgClass(exam.percentage)}`}
                >
                  {exam.percentage}%
                </td>
              ))}
              <td
                className={`px-4 py-2 ${getBgClass(Number(classResult.totalPercentage))}`}
              >
                {classResult.totalPercentage}%
              </td>
            </tr>
          ))}
          <tr className="bg-gray-200">
            <td className="px-4 py-2 text-themeColor font-bold">Total</td>
            {averagePercentages.map((average, index) => (
              <td key={index} className={`px-4 py-2 font-bold ${getBgClass(average)}`}>
                {average}%
              </td>
            ))}
            <td className={`px-4 py-2 font-bold ${getBgClass(Number(totalPercentage))}`}>
              {totalPercentage}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ViewResults;
